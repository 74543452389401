// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Styling for how component pieces are arranged in relation to each other.

$left-card-width: 30%;
$right-card-width: 100% - $left-card-width;

div.liftingView {
  background-color: white;
  // Padding instead of margin, because this affects the full screen view.
  padding: 10px 10px 50px 10px;
}

// The containing div of the div.leftCard and div.rightCard.
div.middleParentContainer {
  display: flex; // Lay out child nodes horizontally.
}

// The container of the LeftCard.
div.leftCardContainer {
  display: block;
  width: $left-card-width;
  padding-right: 10px;
}

// The container of the LiftingTable.
div.rightCardContainer {
  display: block;
  width: $right-card-width;

  max-height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
}

